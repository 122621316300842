import React, { useState } from 'react';
import { navigate } from 'gatsby';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { formatDay, formatVideoEmbedLink, getMomentFromServerDate } from '../../utils/helpers';
import { emptyCart, addCartItem } from '../../utils/cart';
import Nl2br from '../nl2br';
import ProgressBar from './progress-bar';
import CountdownTimer from './countdown-timer';
import TailwindMarkdown from '../common/tailwind-markdown';
import ShareLinks from './share-links';
import FormModal from '../overlays/form-modal';


function BowlingCenter({ center }) {
  const { name, address, city, state, zip, phone } = center;
  return (
    <div className="mt-10">
      <h3 className="text-sm font-medium text-gray-900">Bowling Center</h3>

      <div className="mt-4 text-sm">
        <p className="font-medium text-gray-700">{name}</p>
        <p className="text-gray-700">{address}, {`${city} ${state} ${zip}`}</p>
        {!!phone && <p className="text-gray-700">{phone}</p>}
      </div>
    </div>
  );
}


export default function Overview({ product }) {
  const [showModal, setShowModal] = useState(false);
  const { name, image, short_description: shortDescription, description, session_fee: sessionFee, min_age: minAge, team_size: teamSize, schedule_info: schedule, total_stock: total, purchased, price, start_date: startDate, start_time: startTime, registration_end_date: closeDate, league, center, team_registrations: teamRegistrations } = product;

  const { video } = league;
  const videoUrl = formatVideoEmbedLink(video);

  const closeDateMoment = getMomentFromServerDate(closeDate);
  const today = moment(moment().format('Y-M-D'));
  const pastCloseDate = today.isAfter(closeDateMoment);

  const canRegister = !!(total - purchased) && !pastCloseDate;

  const launchRegistration = () => {
    emptyCart();
    addCartItem(product);
    navigate('/checkout');
  };


  const notifyForm = {
    noContainer: true,
    type: 'post',
    postRoute: `/notification-request`,
    successNotification: 'Your notification request succeeded.',
    beforePost: (values) => {
      // Add center id and league id
      return {
        ...values,
        center_id: center.id,
        league_id: league.id,
      };
    },
    successHandler: (values, actions) => {
      actions.resetForm();
      setShowModal(false);
    },
    fields: [
      {
        name: 'fname',
        label: 'First Name',
        type: 'text',
        required: true,
      },
      {
        name: 'lname',
        label: 'Last Name',
        type: 'text',
        required: true,
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        required: true,
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'phone',
        helpText: 'Must be a mobile phone.',
        required: true,
      },
    ],
    submitLabel: `Notify Me`,
  };

  return (
    <div className="bg-white">
      <div className="pt-6">
        
        <div className="max-w-2xl mx-auto sm:px-6 lg:max-w-7xl lg:pb-6 lg:px-8 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8">
          <div className="lg:col-span-2 px-4 sm:px-0">
            <img
              src={image}
              alt={name}
              className="w-full h-full object-center object-cover rounded"
            />
          </div>
          <div className="lg:col-span-1 px-4 sm:px-0">
            <h1 className="text-xl font-extrabold tracking-tight text-gray-900 mt-2 mb-4 sm:mt-0 sm:text-2xl">{name}</h1>
            {!!shortDescription && <p className="text-gray-700 mt-2"><Nl2br str={shortDescription} /></p>}
            {!!schedule && <p className="text-gray-700 mt-2"><Nl2br str={schedule} /></p>}
            <p className="text-gray-700 mt-4">Starts on {formatDay(startDate, 'dddd, MMMM Do')} at {startTime}</p>
            <p className="text-gray-700">Minimum Age: {minAge}</p>
            {!!teamSize && <p className="text-gray-700">Team Size: {teamSize}</p>}


            <p className="text-lg mt-8">{!!teamRegistrations ? 'Team' : 'League'} Registration Fee: <span className="text-gray-900"><NumberFormat value={price} displayType="text" thousandSeparator prefix="$" /></span></p>
            <p className="text-sm">Includes <span className="font-bold">1st Session FREE Trial</span> and our{' '}
              <span className="font-bold italic">Money Back Guarantee</span>{' '}on your registration fee.{' '}
              <span className="italic">Love it or we'll cheerfully refund 100% of your registration fee.</span>
            </p>
            {!!sessionFee && (
              <p className="mt-8">Per Session Fee: <span className="text-gray-900"><NumberFormat value={sessionFee} displayType="text" thousandSeparator prefix="$" /></span>
               {!!teamRegistrations && <>{"\n"}Per Team Member<br />(<span className="text-gray-900"><NumberFormat fixedDecimalScale decimalScale={2} value={sessionFee * teamSize} displayType="text" thousandSeparator prefix="$" /></span> Total).</>}
              </p>
            )}
            

            {canRegister && (
              <div className="mt-10">
                <button
                  type="submit"
                  onClick={launchRegistration}
                  className="mt-10 w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Register Now
                </button>
              </div>
            )}

            <div className="mt-10">
              {canRegister && <p className="text-gray-700 mb-3">Not ready to register?</p>}
              <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="bg-gray-200 border border-transparent rounded-md py-3 px-6 flex items-center justify-center text-base font-medium text-gray-800 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Notify Me About Upcoming Leagues
              </button>
            </div>
            <FormModal
              form={notifyForm}
              open={showModal}
              cancel={() => setShowModal(false)}
              title="Notify Me"
              body={`Receive notifications when new leagues like this one are added at ${center ? center.name : 'this bowling center'}.`}
            />
          </div>
        </div>
        
        <ShareLinks product={product} />
        
        {/* Product info */}
        <div className="max-w-2xl mx-auto pt-4 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:pt-6 lg:pb-24 lg:px-8 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            {/* Video */}
            {!!videoUrl && (
              <div className="mb-6 max-w-2xl mx-auto sm:px-6">
                <div className="aspect-w-16 aspect-h-9">
                  <iframe src={videoUrl} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
              </div>
            )}
            {!!description && (
              <div>
                <h3 className="text-gray-900 text-lg">About The League</h3>

                {/* <div className="space-y-6">
                  <p className="text-base text-gray-900"><Nl2br str={description} /></p>
                </div> */}
                <div className="text-base text-gray-900 font-sans">
                  <TailwindMarkdown source={description} />
                </div>
              </div>
            )}
          </div>

          {/* Options */}
          <div className="mt-4 lg:mt-0 lg:row-span-3">
            <h2 className="sr-only">Product information</h2>


            <div className="">
              <ProgressBar total={total} purchased={purchased} />
            </div>

            <div className="mt-6">
              <CountdownTimer startDate={startDate} closeDateMoment={closeDateMoment} closed={pastCloseDate} />
            </div>

            <BowlingCenter center={center} />


            
          </div>

          <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            {/* Description and details */}
            



          </div>
        </div>
      </div>
    </div>
  )
}