import React, { useEffect, useState } from 'react';
import Container from '../components/layout/default/container';
import { getApiRequest } from '../utils/request';
import Overview from '../components/products/overview';
import { navigate } from 'gatsby-link';
import SEO from '../components/layout/seo';

export default function Product({ pageContext }) {
  const [freshProduct, setFreshProduct] = useState(null);
  const { product } = pageContext;

  useEffect(() => {
    // Load a fresh copy of the product from the server, to get the LATEST info
    const init = async () => {
      const url = `/product/${product.id}`;
      try {
        const result = await getApiRequest(url);
        setFreshProduct(result);
      } catch (err) {
        console.log(err, 'error');
        // Get out of here, go to the center page...
        navigate(`/${product.center.slug}`);
      }
    };

    init();
  }, []);

  // console.log(product, freshProduct, 'product and fresh product');
  return (
    <Container>
      <SEO title={product.name} />
      <Overview product={freshProduct || product} />
    </Container>
  );
}