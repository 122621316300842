import React from 'react';

export default function ProgressBar({ total, purchased }) {
  if (!total) {
    return null;
  }
  const diff = total - purchased;
  const label = diff ? `${diff} Spot${diff === 1 ? '' : 's'} Left` : 'Sold Out';

  const percent = Math.round((purchased/total) * 100);
  const noDanger = percent < 90;
  const textColor = noDanger ? 'text-green-700' : 'text-red-700';
  const bgColor = noDanger ? 'bg-green-200' : 'bg-red-200';
  const barColor = noDanger ? 'bg-green-600' : 'bg-red-500';

  return (
    <div className="relative pt-1">
      <div className="flex mb-2 items-center justify-between">
        <div>
          <span className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full ${textColor} ${bgColor}`}>
            {label}
          </span>
        </div>
        <div className="text-right">
          <span className={`text-xs font-semibold inline-block ${textColor}`}>
            {total}
          </span>
        </div>
      </div>
      <div className={`overflow-hidden h-2 mb-4 text-xs flex rounded ${bgColor}`}>
        <div style={{ width: `${percent}%` }} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${barColor}`} />
      </div>
    </div>
  );
}
