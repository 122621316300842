import React from 'react';
import moment from 'moment';
import { dateLeadingZero, getMomentFromServerDate, formatDay, pluralize } from '../../utils/helpers';

export default function CountdownTimer({ startDate, closeDateMoment, closed }) {

  const now = moment();
  const startDateAsMoment = getMomentFromServerDate(startDate);
  
  const diffWeeks = startDateAsMoment.diff(now, 'weeks');
  const diffDays = startDateAsMoment.diff(now, 'days') % 7;

  const inPast = diffWeeks < 0 || diffDays < 0;

  const regMessage = closed ? 'Registration has close.' : `The last day to register is ${closeDateMoment.format('MMMM Do YYYY')}.`;

  return (
    <>
      <div className="bg-gray-800 flex items-center justify-center px-5 py-5 rounded-lg">
        <div className="text-gray-100">
          <h1 className="text-lg text-center mb-3">{inPast ? 'League Started' : 'Starting In'}</h1>
          <div className="text-6xl text-center flex w-full items-center justify-center">
            <div className="w-24 mx-1 p-2 bg-white text-gray-700 rounded-lg">
              <div className="font-mono leading-none" x-text="days">{dateLeadingZero(diffWeeks)}</div>
              <div className="font-mono uppercase text-sm leading-none">{pluralize('Week', diffWeeks)}</div>
            </div>
            <div className="text-lg mx-1 font-extralight">&amp;</div>
            <div className="w-24 mx-1 p-2 bg-white text-gray-700 rounded-lg">
              <div className="font-mono leading-none" x-text="hours">{dateLeadingZero(diffDays)}</div>
              <div className="font-mono uppercase text-sm leading-none">{pluralize('Day', diffDays)}</div>
            </div>
            {inPast && <div className="text-lg mx-1 font-extralight">ago</div>}
          </div>
          <h1 className="text-lg text-center mt-3">On {formatDay(startDate, 'MMMM Do YYYY')}</h1>
        </div>
      </div>
      <div className="my-4 px-2">
        <p className="text-sm text-center text-gray-500">{regMessage}</p>
      </div>
    </>
  );
}